import * as React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import axios from 'axios';
import * as Tools from "../../utils/tools";
import {Button} from "@mui/material";
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];

export default function ArticleDataTable(prop: any) {

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'title', headerName: 'Titre', width: 130 },
    { field: 'content', headerName: 'Contenu', width: 130,
      renderCell: (params)=>{
         return <>
            <div contentEditable="true">
            {params.value}
            </div>
       </> 
     }
     },
    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 91,
    // },
    {
      field: 'category',
      headerName: 'Categorie',
      description: 'Type d\'article',
      sortable: false,
      width: 120,
      //   valueGetter: (params: GridValueGetterParams) =>
      //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      // },
    },
    {
      field: 'isPublished',
      headerName: 'Publié',
      description: 'Voulez-vous publié l\'article',
      sortable: false,
      width: 100, 
      renderCell: (params)=>{
          console.log(params)    
      return <>
            {parseInt(params.value)>0?"Oui":"Non"}
         </>
      }
    },
    {
      field: 'operations',
      headerName: 'Operations',
      description: 'This column contains buttons to update each row',
      sortable: false,
      width: 160,
      renderCell: (params) => {
        // console.log(params);
        const editUser = (e: any) => {
          console.log('editiing ' + params.id);
          prop.toggleUpdate(true, params.id);
        }
        const deleteUser = (e: any) => {
          console.log('deleting ' + params.id);
          prop.toggleDelete(true, params.id);
          //prop.toggleUpdate(true, params.id);
        }
        const publishArticle=()=>{
           prop.publishArticle(params.id);
        } 
        const unPublishArticle=()=>{
          prop.unPublishArticle(params.id);
        }
        return <>
          {/* <Button onClick={publishArticle}>
Publier
          </Button>
          <Button onClick={unPublishArticle}>
Privée
          </Button> */}
          <IconButton aria-label="Publié" color="primary" onClick={publishArticle} ><PublicIcon /></IconButton>
          <IconButton aria-label="Ne pas publié" color="primary" onClick={unPublishArticle} ><PublicOffIcon /></IconButton>
          <IconButton aria-label="Modification article" color="primary" onClick={editUser} ><ModeEditSharpIcon /></IconButton>
          <IconButton arial-label="Delete employe" color="secondary" onClick={deleteUser}><DeleteOutlineOutlinedIcon /></IconButton>
        </>
      }
    }
  ];
  const [employees, setEmployees]: [any, any] = React.useState({
    all: []
  })

  const searching = () => {
    //if (prop.enableSearch) {
    //let callback = v => v % 2 === 0;
    let searchCallBack = (element: any) => {
      // console.log(searchQuery.userName + '===' + prop.searchQuery);
      // console.log(/prop.searchQuery/i.test(searchQuery.userName.toLowerCase()));
      // return prop.searchQuery.toLowerCase()===element.userName.toLowerCase();
      return element.title.toLowerCase().includes(prop.searchQuery.toLowerCase()) 
      || element.category.toLowerCase().includes(prop.searchQuery.toLowerCase()) ||
        element.content.toLowerCase().includes(prop.searchQuery.toLowerCase())  
    }
    console.log('searching' + prop.searchQuery)
    console.log('employees found', employees.all.length);
    // const even = employees.filter(callback);
    if (prop.searchQuery.length > 0) {
      // getEmployees();
      const found = employees.all.filter(searchCallBack);

      setEmployees({
        ...employees,
        all: found
      });

      console.log('found', found);
    } else {
      getEmployees();
    }
    //}
  }
  //get employees
  const getEmployees = () => {

    Tools.getLightAxios('jhkjh').post(Tools.getHost() + "adminapi/allarticles",{loginToken: 'sdfsdfsdf'}).then((res: any) => {
      // console.log(res.data);
      if (res.data.articles.length) {

        const totalLength = res.data.articles.length;
        if (res.data.status === 'success') {
          // console.log('my data');
          //format employees 

          // { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
          let formatedDatas = [];
          // formatedDatas.push({ id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 })
          for (let i = 0, c = res.data.articles.length; i < c; i++) {
            // console.log(res.data[i].userName);
            formatedDatas.push({ id: res.data.articles[i].id, title: res.data.articles[i].articleTitle, content: res.data.articles[i].content, category: res.data.articles[i].category, isPublished: res.data.articles[i].isPublished });

          }
          //console.log('formated datas: ');
          //console.log(formatedDatas);
          setEmployees({
            ...employees,
            all: formatedDatas
          });
        } else {
          // console.log('error');
        }
      } else {
        // console.log('error');
      }

    })
  }
  const [calledOnce, setCalledOnce] = React.useState(false);
  React.useEffect(() => {
    if (prop.enableSearch) {
      searching();
    }
    //else {
    if (prop.newUserCreated || prop.userUpdated || prop.userDeleted || prop.articlePublished)
      getEmployees();
    if (!calledOnce) {
      getEmployees();
      setCalledOnce(true);
    }
    //}
  }, [prop.newUserCreated, prop.userUpdated, prop.userDeleted, prop.enableSearch, prop.searchQuery, prop.articlePublished])

  return (
    <div className="datatable-custom" style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={employees.all}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
}
