import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

// Define a custom interface for response data structure if needed
interface MyResponseData {
    // Define the structure of your response data
}

export class LightAxios {
    private axiosInstance: AxiosInstance;

    constructor(baseURL: string) {
        this.axiosInstance = axios.create({
            baseURL,
        });
        // axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
        if (localStorage.getItem('authToken'))
            this.axiosInstance.defaults.headers.common['Authorization'] = localStorage.getItem('authToken');
        // You can configure your axios instance here further if needed
        // Example: setting up default headers
        // this.axiosInstance.defaults.headers.common['Authorization'] = 'Bearer token';
    }

    // Custom method to perform GET request
    async get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.axiosInstance.get<T>(url, config);
    }

    // Custom method to perform POST request
    async post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.axiosInstance.post<T>(url, data, config);
    }

    // Add more methods as needed for other HTTP verbs like PUT, DELETE, etc.
}

// export default LightAxios;
// Example usage of CustomAxios class
// const customAxios = new CustomAxios('https://api.example.com');

// // Making a GET request
// customAxios.get<MyResponseData>('/data')
//   .then(response => {
//     // Handle successful response
//     console.log('Response Data:', response.data);
//   })
//   .catch(error => {
//     // Handle error
//     console.error('Error:', error);
//   });
