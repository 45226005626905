import React, { useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import axios from "axios";
import parse from "html-react-parser";
import { HiUser } from "react-icons/hi";
import * as tools from "../../utils/tools";
import AppContext, { AppContextProvider } from "../../context/AppContext";
import Logo from "../../image/footer-logo-400x400.png";

import { FormControl, TextField, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const baseURL: string = process.env.REACT_APP_API_URL + '';
const appHost: string = process.env.REACT_APP_APP_HOST + '';


export const AdminLogin = (prop: any) => {

	const navigate = useNavigate();
	const [loginState, setLoginState] = useState({
		password: '',
		login: ''
	});

	const [errorState, setErrorState] = useState({
		error: ''
	});

	const { setIsLogin, loginAdmin, adminAuthInfo, isLogin, checkToken, errorLogin, userInfo, setUserInfo }: any = useContext(AppContext);

	const submitLogin = (e: any) => {
		e.preventDefault();
		showError();
		setClickSubmit(true);
		// console.log(appHost + 'adminapi/login/');
		// hideError();
		// console.log('logged in');
		loginAdmin(loginState.login, loginState.password);
		if (adminAuthInfo.loggedIn) {
			setClickSubmit(false);
		}else{
			setTimeout(()=>{
				setClickSubmit(true);
			}, 2000); //waiting 2s to reconnect again
		}
	}
	const handleLogin = (e: any) => {

		setLoginState({
			...loginState,
			[e.target.name]: e.target.value
		});
	}
	const [hideLoginError, setHideLoginError] = useState(false);
	const hideError = () => {
		setHideLoginError(true);
	}
	const showError = () => {
		setTimeout(() => {
			setHideLoginError(false);
		}, 400)
	}

	const [userLanguage, setUserLanguage]: [any, any] = useState({
		currentLanguage: ''
	});

	const handleLanguageForm = (e: any) => {
		setUserLanguage({
			userLanguage,
			[e.target.name]: e.target.value
		})
	}
	const changeLanguage = () => {
		setUserInfo((prevState: any) => ({
			...prevState,
			defaultLanguage: userLanguage.currentLanguage
		}))
		localStorage.setItem('currentLanguage', userLanguage.currentLanguage);
	}

	const [clickSubmit, setClickSubmit] = useState(false);
	React.useEffect(() => {
		//errorState.error='';
	}, []);


	return <>

		<div className="app-core">

			<div className="app-login">

				<div className="app-login-wrap">
					{/* <div> 
						<p>{isLogin?"logged in":"not logged in"}</p>
					</div> */}
					<form onSubmit={submitLogin} className="login-form">

						<div className="login-title">
							<div className="login-logo-wrap">
								{/* <img src={Logo} style={{
									width: "50px", height: "50px",
									borderRadius: "100%", background: "#000"
								}} /> */}
								<h2 className="elem-text-align-center elem-width-full elem-flex-center-inline elem-font-upper">{tools.translate('Admin')}</h2>

							</div>
						</div>
						<div className="login-error">
							{errorLogin ? <><div className="error-login-wrap" style={{ display: hideLoginError ? "none" : "flex" }}>{tools.translate('Wrong email or password')}</div></> : <></>}
						</div>
						<label>{tools.translate('Login')}
							<input name="login" type="text" onChange={handleLogin} onMouseEnter={hideError} />
						</label>

						{/* 
						<FormControl fullWidth sx={{ m: 1 }}>
							<TextField id="standard-basic" label="Pseudo" name="login" variant="standard" onChange={handleLogin} />
						</FormControl> */}
						<br />
						<label>{tools.translate('Password')}
							<input name="password" type="password" onChange={handleLogin} onMouseEnter={hideError} />
						</label>
						<br />
						{/*<p className="error" style={{display: errorState.error==='incorrect login'?"block":"none"}}>Login incorrecte</p> */}
						{errorState.error == 'error' ? (<><p className="error">{tools.translate('Account not found')}</p></>) : (<><p className="message-sent" style={{ display: errorState.error == 'success' ? "block" : "none" }}>{tools.translate('Welcome')}</p></>)}
						<div className="login-footer">
							{/* <button className="app-bt" >{tools.translate('Connect')}</button> */}
							{clickSubmit ? <>
								<LoadingButton
									loading
									loadingPosition="start"

									variant="outlined"
									type="submit"
									className="app-bt"
									style={{paddingLeft: "34px"}}
								>
									Se connecter
								</LoadingButton></> : <>
								<Button variant="outlined" type="submit" className="app-bt">
									Se connecter
								</Button></>}
						</div>
					</form>

					<div>
						<p className="loginMessage"></p>
					</div>
				</div> {/*end app login wrap*/}
				<div className="language-wrap text-center" style={{ maxWidth: '250px', margin: '0 auto' }}>

				</div>
				{/* <p className="elem-text-center">{tools.translate('Fiteny voatahiry')+' '+ userLanguage.currentLanguage }</p> */}

			</div>
		</div>
	</>
}


export default AdminLogin;
