import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AppContext from '../../context/AppContext';
import { IconButton } from '@mui/material';
import { HiUser } from 'react-icons/hi';
export default function GasManagementProfileMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { logoutAdmin, logout, userInfo,setUserInfo } = React.useContext(AppContext); 

  return (
    <div>
      
      <Button aria-label="Adding user" color="primary" onClick={handleClick} id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined} startIcon={<HiUser />}>
        {userInfo.name}
      </Button>
      {/* <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Menu Admin
      </Button> */}
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleClose}>Modifier</MenuItem>
        <MenuItem onClick={handleClose}>Paramètres</MenuItem>
        <MenuItem onClick={logout}>Déconnexion</MenuItem>
      </Menu>
    </div>
  );
}