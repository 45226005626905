import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import DashboardHeader from "../header/DashboardHeader";
import LeftMenu from "../dashboard/LeftMenu";
import UsersDataTable from "../users/UsersDataTable";
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { Box, Button, Typography, Modal, TextField, FormControl, Alert } from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Person } from "@mui/icons-material";
import axios from "axios";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import * as Tools from "../../utils/tools";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import InputLabel from "@mui/material/InputLabel";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArticleDataTable from './ArticleDataTable';

const baseURL: string = process.env.REACT_APP_API_URL + '';
const appHost: string = process.env.REACT_APP_APP_HOST + '';

const ArticleList = () => {

    // create new user modal 
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
        setUserCreated(false);
        setShowErrorForm(false);
    }
    const handleClose = () => {
        setOpen(false);
        setUserCreated(false);
        setShowErrorForm(false);
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid transparent',
        boxShadow: 24,
        p: 4,
        borderRadius: '5px'
    };
    //delete modal 
    const [openDelete, setOpenDelete] = React.useState(false);
    const handleOpenDelete = () => {
        setOpenDelete(true);
        setUserDeleted(false);
        setShowErrorFormDelete(false);
        setClickSubmitDelete(false);
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setUserDeleted(false);
        setShowErrorFormDelete(false);
        // setClickSubmitUpdate(false);
    }
    //udpate modal
    const [openUpdate, setOpenUpdate] = React.useState(false);

    const handleOpenUpdate = () => {
        setOpenUpdate(true);
        setUserUpdated(false);
        setShowErrorFormUpdate(false);
        setClickSubmitUpdate(false);
    }

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
        setUserUpdated(false);
        setShowErrorFormUpdate(false);
        // setClickSubmitUpdate(false);
    }

    //handling user creation 
    const [userCreated, setUserCreated] = useState(false);
    const [showErrorForm, setShowErrorForm] = useState(false);
    //creation
    const [userCreationState, setUserCreationState] = useState({
        userName: '',
        userEmail: '',
        userLogin: '',
        userPassword: '',
        userPost: '',
        userId: 0
    });
    const handleUserCreation = (e: any) => {
        setUserCreationState({
            ...userCreationState,
            [e.target.name]: e.target.value
        })
    }
    //udpate

    const [showErrorFormUpdate, setShowErrorFormUpdate] = useState(false);
  

    const handleUserUpdate = (e: any) => {
        setUserUpdateState({
            ...userUpdateState,
            [e.target.name]: e.target.value
        })
    }

    //creation
    const [clickSubmit, setClickSubmit] = useState(false);
    const [articleCreationState, setArticleCreationState] = useState({
        title: '',
        content: '',
        category: '',
        previewImageId: 0,
        previewImageSrc: ''
    })
    const submitArticleCreation = (e: any) => {
        e.preventDefault();
        console.log('Creating new user');
        setClickSubmit(true);
        axios.post(appHost + 'adminapi/newarticle',
            {
                title: articleCreationState.title,
                content: newNote,
                category: articleCreationState.category,
                previewImageId: articleCreationState.previewImageId,
                previewImageSrc: articleCreationState.previewImageSrc
            }
        ).then((res: any) => {
            console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                setUserCreated(true);
                setShowErrorForm(false);
                //added new user, should update list
                e.target.reset();
                setClickSubmit(false);
            } else if (res.data.status.toLowerCase() === 'error') {
                setUserCreated(false);
                setShowErrorForm(true);

                setClickSubmit(false);
            }
        })
    }
    const [creationState, setCreationState]: [any, any] = useState({});
    const handleCreation = (e: any) => {
        setArticleCreationState({
            ...articleCreationState,
            [e.target.name]: e.target.value
        })
    }
    //update
    const [clickSubmitUpdate, setClickSubmitUpdate] = useState(false);
    const [userUpdated, setUserUpdated] = useState(false);
    const [userUpdateState, setUserUpdateState] = useState({
        title: '',
        content: '',
        type: '',
        published: '',
        date: '',
        id: 0,
        category: ''
    });
    const submitUserUpdate = (e: any) => {
        e.preventDefault();

        setClickSubmitUpdate(true);
        axios.post(appHost + 'adminapi/updatearticle',
            {
                title: userUpdateState.title,
                content: updatedArticle? newNoteUpdate: userUpdateState.content,
                category: userUpdateState.category,
                published: userUpdateState.published,
                date: userUpdateState.date,
                id: userUpdateState.id
            }
        ).then((res: any) => {
            console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                setUserUpdated(true);
                setShowErrorFormUpdate(false);
                //added new user, should update list
                e.target.reset();
                setClickSubmitUpdate(false);
            } else if (res.data.status.toLowerCase() === 'error') {
                setUserUpdated(false);
                setShowErrorFormUpdate(true);
                setClickSubmitUpdate(false);
            }
        })
    }
    const toggleUpdateModal = (valShowModal: any, articleId: any) => {
        //setOpenUpdate(!valShowModal);
        handleOpenUpdate();
        console.log('updating ' + articleId);
        axios.post(Tools.getHost() + "adminapi/getarticle", {
            id: articleId
        }).then((res: any) => {
            if (res.data.status.toLowerCase() === 'success') {
                console.log('article found ', res.data);

                setUserUpdateState({
                    ...userUpdateState,
                    title: res.data.article.title,
                    id: res.data.article.id,
                    content: res.data.article.content,
                    category: res.data.article.category,
                    published: res.data.article.published
                })
                console.log('rocket here');

            } else {
                console.log('some error occured');
            }
        })
    }
    const toggleDeleteModal = (valShowModal: any, articleId: any) => {
        //setOpenUpdate(!valShowModal);
        handleOpenDelete();
        //console.log('deleting ' + articleId);
        axios.post(Tools.getHost() + "/adminapi/getuserbyid", {
            userId: articleId
        }).then((res: any) => {
            if (res.data.status.toLowerCase() === 'success') {
                console.log('article to delte found ', res.data);
                setUserDeleteState({
                    ...userDeleteState,
                    userId: res.data.userId,
                })
                console.log('===to delete ' + res.data.userId);
            } else {
                console.log('some error occured');
            }
        })

    }
    //delete modal submit 
    const [clickSubmitDelete, setClickSubmitDelete] = useState(false);
    const [userDeleted, setUserDeleted] = useState(false);
    const [showErrorFormDelete, setShowErrorFormDelete] = useState(false);
    const submitUserDelete = (e: any) => {
        e.preventDefault();
        console.log('Deleting new user');
        setClickSubmitDelete(true);
        axios.post(appHost + '/adminapi/deleteuserbyid',
            {
                userId: userDeleteState.userId
            }
        ).then((res: any) => {
            console.log(res.data);
            if (res.data.status.toLowerCase() === 'success') {
                setUserDeleted(true);
                setShowErrorFormDelete(false);
                //added new user, should update list
                e.target.reset();
                setClickSubmitDelete(false);
            } else if (res.data.status.toLowerCase() === 'error') {
                setUserDeleted(false);
                setShowErrorFormDelete(true);
                setClickSubmitDelete(false);
            }
        })
    }
    const [userDeleteState, setUserDeleteState] = useState({
        userId: 0
    })
    const handleUserDelete = (e: any) => {
        setUserDeleteState({
            ...userDeleteState,
            [e.target.name]: e.target.value
        })
    }
    //search feature 
    const [enableSearch, setEnableSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState({
        search: ''
    });
    const searchUser = () => {
        setEnableSearch(true);
        //setSearchQuery(searchQuery.search);
    }
    const closeSearch = () => {
        setEnableSearch(false);
    }
    const handleSearch = (e: any) => {
        setEnableSearch(true);
        //add custom code to handle on change to get all employees
        setSearchQuery({
            ...searchQuery,
            [e.target.name]: e.target.value
        })
    }
    const submitUserSearch = (e: any) => {
        e.preventDefault();
        searchUser();
    }
    const [assignTo, setAssignTo]: [any, any] = useState();
    const [newNote, setNewNote] = useState('');
    const handleNoteEditor = React.useCallback((e: any) => {
        //console.log(e.target.innerHTML);
        // const sanitizeOptions={
        // 	allowedTags: ["b", "i", "a", "p", "div", "img", "br", "video", "audio", "strong"],
        // 	allowedAttributes: {a: ["href"], 
        // 						img: ["src", "alt"]}
        // };
        // console.log('sanitized ',sanitizeHtml(e.target.innerHTML, sanitizeOptions));
        // setNewNote(sanitizeHtml(e.target.innerHTML, sanitizeOptions));
        setNewNote(e.target.innerHTML);
    }, [])
    const [newNoteUpdate, setNewNoteUpdate] = useState('');
    const [updatedArticle,setUpdatedArticle]=useState(false);

    const handleNoteEditorUpdate = React.useCallback((e: any) => {
        //console.log(e.target.innerHTML);
        // const sanitizeOptions={
        // 	allowedTags: ["b", "i", "a", "p", "div", "img", "br", "video", "audio", "strong"],
        // 	allowedAttributes: {a: ["href"], 
        // 						img: ["src", "alt"]}
        // };
        // console.log('sanitized ',sanitizeHtml(e.target.innerHTML, sanitizeOptions));
        // setNewNote(sanitizeHtml(e.target.innerHTML, sanitizeOptions));
        setNewNoteUpdate(e.target.innerHTML);
        setUpdatedArticle(true);
    }, [])

    //handle dialog open
    const [openScrollModal, setOpenScrollModal] = React.useState(false);
    const [scrollModal, setScrollModal] = React.useState<DialogProps['scroll']>('body');

    const handleOpenScrollModal: any = (scrollType: DialogProps['scroll']) => () => {
        setOpenScrollModal(true);
        // setScrollModal(scrollType);
    };

    const handleCloseScrollModal = () => {
        setOpenScrollModal(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    const [publishState, setPublishState] = useState(false);

    const publishArticle = (id: any) => {
        axios.post(Tools.getHost() + 'adminapi/publisharticle', { articleId: id }).then((res: any) => {
            console.log(res.data);
            if (res.data.status.toLowerCase() == 'success') {
                setPublishState(true);
            } else {
                setPublishState(false);
            }
        })
    }
    const unPublishArticle = (id: any) => {
        axios.post(Tools.getHost() + 'adminapi/unpublisharticle', { articleId: id }).then((res: any) => {
            console.log(res.data);
            if (res.data.status.toLowerCase() == 'success') {
                setPublishState(true);
            } else {
                setPublishState(false);
            }
        })
    }

    React.useEffect(() => {
        if (openScrollModal) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openScrollModal]);
    return <>
        <DashboardHeader />
        <div className="dashboard-core">
            <div className="left-content">
                <LeftMenu />
            </div>
            <div className="main-content">
                <div className="elem-light-title">
                    <div className="elem-row-flex">
                        <div>
                            <h2>Gestion article</h2>
                            {/* <IconButton aria-label="Adding user" color="primary" onClick={handleOpen}>
                        <AddCircleOutlineRoundedIcon />
                    </IconButton> */}
                            <Button aria-label="Adding user"
                                startIcon={<AddCircleOutlineRoundedIcon />} color="primary" onClick={handleOpenScrollModal('body')}>
                                Créer
                            </Button>
                        </div>
                        <div className="search-form-col">
                            <form onSubmit={submitUserSearch} >
                                <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                                    <InputLabel htmlFor="search-input-top">Recherche</InputLabel>
                                    <Input
                                        id="search-input-top"
                                        type="text"
                                        onChange={handleSearch}
                                        name="search"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="User search"
                                                    onClick={searchUser}
                                                    onMouseDown={searchUser}

                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </form>
                            {/* {searchQuery.search} */}
                        </div>
                    </div>
                </div>
                {/*add new user modal using dialog*/}
                <Dialog
                    open={openScrollModal}
                    onClose={handleCloseScrollModal}
                    scroll={scrollModal}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">Ecrire un article</DialogTitle>
                    <DialogContent dividers={scrollModal === 'paper'}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >

                            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography> */}
                            <form action="" onSubmit={submitArticleCreation}>
                                <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Titre article" name="title" variant="standard" onChange={handleCreation} />
                                </FormControl>
                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "95%" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Type article </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={assignTo}
                                        onChange={handleCreation}
                                        label="ArticleType"
                                        name="category"
                                    >

                                        <MenuItem value="notes">
                                            <em>Notes</em>
                                        </MenuItem>
                                        <MenuItem value="blog">
                                            <em>Blog</em>
                                        </MenuItem>
                                        <MenuItem value="services">
                                            <em>Services</em>
                                        </MenuItem>
                                        <MenuItem value="ads">
                                            <em>Ads</em>
                                        </MenuItem>
                                        <MenuItem value="product">
                                            <em>Product</em>
                                        </MenuItem>
                                        <MenuItem value="book">
                                            <em>Book</em>
                                        </MenuItem>
                                        <MenuItem value="">
                                            <em>Others</em>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <div>
                                    <label  >Votre note</label>
                                    <div className="note-editor" style={{ border: '1px solid #ddd;min-height:400px;' }} contentEditable onBlur={handleNoteEditor} dangerouslySetInnerHTML={{ __html: '' }}>
                                    </div>
                                </div>
                                <input type="hidden" name="previewImageId" onChange={handleCreation} value={0} />
                                <input type="hidden" name="previewImageSrc" onChange={handleCreation} value="" />

                                {/* <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Pseudo" name="userLogin" variant="standard" onChange={handleUserCreation} />
                                </FormControl>

                                <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Adresse e-mail" name="userEmail" variant="standard" onChange={handleUserCreation} />
                                </FormControl> */}

                                {/* <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Poste" name="userPost" variant="standard" onChange={handleUserCreation} />
                                </FormControl> */}

                                {/* <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Mot de passe" name="userPassword" variant="standard" type="password" onChange={handleUserCreation} />
                                </FormControl> */}
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                    {clickSubmit ? <>
                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<PersonAddIcon />}
                                            variant="outlined"
                                            type="submit"
                                        >
                                            Enregistrer
                                        </LoadingButton></> : <>
                                        <Button variant="outlined" startIcon={<PersonAddIcon />} type="submit">
                                            Enregister
                                        </Button></>}

                                </div> <br />
                                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ display: userCreated ? "flex" : "none" }}>
                                    L'article a bien été enregistré
                                </Alert>

                                <Alert icon={<CloseIcon fontSize="inherit" />} severity="error" style={{ display: showErrorForm ? "flex" : "none" }}>
                                    Erreur: Cet article existe déjà
                                </Alert>
                            </form>
                        </DialogContentText>
                    </DialogContent>
                    {/* <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleClose}>Subscribe</Button>
                    </DialogActions> */}
                </Dialog>
                {/* add new user modal */}
                {/* <Button onClick={handleOpen}>Open modal</Button> */}
                <div style={{ overflowY: "scroll" }}>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"

                    >
                        <Box sx={style}>

                            {/* <div>
                                <Typography id="user-creation-info" sx={{ mt: 2 }}>
                                    User name: {userCreationState.userName}<br />
                                    User email: {userCreationState.userEmail}<br />
                                    User login: {userCreationState.userLogin}<br />
                                    User post: {userCreationState.userPost} <br />
                                    User pwd: {userCreationState.userPassword}
                                </Typography>
                            </div> */}

                        </Box>
                    </Modal>
                </div>

                {/* update modal */}
                <div style={{ overflowY: "scroll" }}>
                    <Modal
                        open={openUpdate}
                        onClose={handleCloseUpdate}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        sx={{ overflowY: "scroll" }}
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Modification article
                            </Typography>
                            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography> */}
                            <form action="" onSubmit={submitUserUpdate}>
                                <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Titre article" name="title" variant="standard" onChange={handleUserUpdate} value={userUpdateState.title} />
                                </FormControl>

                                {/* <FormControl fullWidth sx={{ m: 1, width: "95%" }}>
                                    <TextField id="standard-basic" label="Texte article" name="content" variant="standard" onChange={handleUserUpdate} value={userUpdateState.content} />
                                </FormControl> */}
                                <label  >Votre article</label>
                                <div className="note-editor" style={{ border: '1px solid #ddd;min-height:400px;' }} contentEditable onBlur={handleNoteEditorUpdate} dangerouslySetInnerHTML={{ __html: userUpdateState.content }}>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "95%" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label">Type article </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={userUpdateState.category}
                                            onChange={handleCreation}
                                            label="ArticleType"
                                            name="category"
                                        >

                                            <MenuItem value="notes">
                                                <em>Notes</em>
                                            </MenuItem>
                                            <MenuItem value="blog">
                                                <em>Blog</em>
                                            </MenuItem>
                                            <MenuItem value="services">
                                                <em>Services</em>
                                            </MenuItem>
                                            <MenuItem value="ads">
                                                <em>Ads</em>
                                            </MenuItem>
                                            <MenuItem value="product">
                                                <em>Product</em>
                                            </MenuItem>
                                            <MenuItem value="book">
                                                <em>Book</em>
                                            </MenuItem>
                                            <MenuItem value="">
                                                <em>Others</em>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: "95%" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label">Publié article </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={userUpdateState.published}
                                            onChange={handleUserUpdate}
                                            label="ArticleType"
                                            name="published"
                                        >

                                            <MenuItem value={1}>
                                                <em>Oui</em>
                                            </MenuItem>
                                            <MenuItem value={0}>
                                                <em>Non</em>
                                            </MenuItem>

                                        </Select>
                                    </FormControl>
                                </div>
                                <input type="hidden" name="id" value={userUpdateState.id}  onChange={handleUserUpdate}/>
                                {/* <FormControl fullWidth sx={{ m: 1 }}>
                                    <TextField id="standard-basic" label="Mot de passe" name="userPassword" variant="standard" type="password" onChange={handleUserUpdate} />
                                </FormControl> */}
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                    {clickSubmitUpdate ? <>
                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<PersonAddIcon />}
                                            variant="outlined"
                                            type="submit"
                                        >
                                            Enregistrer
                                        </LoadingButton></> : <>
                                        <Button variant="outlined" startIcon={<PersonAddIcon />} type="submit">
                                            Enregister
                                        </Button></>}


                                </div> <br />
                                <input type="hidden" name="userId" value={userUpdateState.id} onChange={handleUserUpdate} />
                                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ display: userUpdated ? "flex" : "none" }}>
                                    L'article a bien été modifié
                                </Alert>

                                <Alert icon={<CloseIcon fontSize="inherit" />} severity="error" style={{ display: showErrorFormUpdate ? "flex" : "none" }}>
                                    Une Erreur s'est produite lors de la modification de l'article
                                </Alert>
                                {/* <div>
                                <Typography id="user-creation-info" sx={{ mt: 2 }}>
                                    User name: {userCreationState.userName}<br />
                                    User email: {userCreationState.userEmail}<br />
                                    User login: {userCreationState.userLogin}<br />
                                    User post: {userCreationState.userPost} <br />
                                    User pwd: {userCreationState.userPassword}
                                </Typography>
                            </div> */}
                            </form>
                        </Box>
                    </Modal>
                </div>
                {/*delete modal */}
                <div style={{ overflowY: "scroll" }}>
                    <Modal
                        open={openDelete}
                        onClose={handleCloseDelete}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"

                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Voulez-vous vraiment effacé cet employé?
                            </Typography>
                            {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                        </Typography> */}
                            <form action="" onSubmit={submitUserDelete}>



                                {/* <FormControl fullWidth sx={{ m: 1 }}>
                                    <TextField id="standard-basic" label="Mot de passe" name="userPassword" variant="standard" type="password" onChange={handleUserUpdate} />
                                </FormControl> */}
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                    {clickSubmitDelete ? <>
                                        <LoadingButton
                                            loading
                                            loadingPosition="start"
                                            startIcon={<PersonRemoveIcon />}
                                            variant="outlined"
                                            type="submit"
                                            color="secondary"
                                        >
                                            Effacer
                                        </LoadingButton></> : <>
                                        <Button variant="outlined" color="secondary" startIcon={<PersonRemoveIcon />} type="submit">
                                            Effacer
                                        </Button></>}


                                </div> <br />
                                <input type="hidden" name="userId" value={userDeleteState.userId} onChange={handleUserDelete} />
                                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ display: userDeleted ? "flex" : "none" }}>
                                    L'employé a bien été supprimé
                                </Alert>

                                <Alert icon={<CloseIcon fontSize="inherit" />} severity="error" style={{ display: showErrorFormDelete ? "flex" : "none" }}>
                                    Une Erreur s'est produite lors de la suppression de l'article
                                </Alert>
                                {/* <div>
                                <Typography id="user-creation-info" sx={{ mt: 2 }}>
                                    User name: {userCreationState.userName}<br />
                                    User email: {userCreationState.userEmail}<br />
                                    User login: {userCreationState.userLogin}<br />
                                    User post: {userCreationState.userPost} <br />
                                    User pwd: {userCreationState.userPassword}
                                </Typography>
                            </div> */}
                            </form>
                        </Box>
                    </Modal>
                </div>
                {/* dataTable for users */}
                <ArticleDataTable searchQuery={searchQuery.search} enableSearch={enableSearch} closeSearch={closeSearch} userDeleted={userDeleted} userUpdated={userUpdated} newUserCreated={userCreated} showModalUpdate={openUpdate} toggleUpdate={toggleUpdateModal} toggleDelete={toggleDeleteModal} publishArticle={publishArticle} unPublishArticle={unPublishArticle} articlePublished={publishState} />
            </div>
        </div>

    </>
}


export default ArticleList;
