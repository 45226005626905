import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import UserFiles from "./UserFiles";
import { BsPlusCircle as AddIcon, BsFillCalendarDateFill as DateIcon, BsPencilSquare as AuthorIcon } from "react-icons/bs";
import { BiPencil as UpdateNoteIcon } from "react-icons/bi";
import * as tools from "../utils/tools";
import Pagination from "../pagination/Pagination";
import ProfileMenu from "./ProfileMenu";
//import sanitizeHtml from "sanitize-html";
const baseURL: string = process.env.REACT_APP_API_URL + '';
const appHost: string = process.env.REACT_APP_APP_HOST + '';

export const AddNote = (props: any) => {

	const navigate = useNavigate();
	const [noteState, setNoteState] = useState({
		noteTitle: '',
		noteContent: ''
	})

	const [noteSaved, setNoteSaved] = useState(false);

	const [showAddNote, setShowAddNote] = useState(false);

	const [userPostStats, setUserPostStats] = useState({
		totalNotes: 0
	});

	const [userNotes, setUserNotes] = useState({
		notes: []
	});
	const [notesState, setNotesState]: [any, any] = useState({
		id: []
	});
	const [showUpload, setShowUpload] = useState(false);

	//we need this to reload pagination after new article submit
	const [paginationReload, setPaginationReload]: [any, any] = useState(0);
	const changeEachNoteState = (id: string) => {
		// console.log(e.target);
		// const id=e.target.id;
		console.log('article id ' + id + ', state ==> ' + notesState[id]);
		setToUpdateTitle('');
		setToUpdateContent('');
		setNotesState((prev: any) => ({
			[id]: !prev[id]
		}))
	}

	const submitNote = (e: any) => {
		e.preventDefault();

		setPaginationReload(false);
		axios.post(baseURL + 'addusernote/', {
			title: noteState.noteTitle,
			//content: noteState.noteContent,
			content: newNote,
			header: localStorage.getItem('loginToken')
		}).then((res: any) => {
			if (res.data.status === 'success') {
				console.log('note saved');
				setNoteSaved(!noteSaved);
				setShowAddNote(false);
				//getAllNotes();
				//setPaginationReload(1);
				//navigate('/profile/#add');
				//window.location.reload();
			} else {
				//setNoteSaved(false);
			}
		})
	}
	const showAddNoteForm = () => {
		setShowAddNote(!showAddNote);
	}

	const showUploadForm = () => {
		setShowUpload(!showUpload);
	}
	const handleNoteChange = (e: any) => {
		setNoteState({
			...noteState,
			[e.target.name]: e.target.value
		})
	}

	const getAllNotes = () => {
		axios.post(baseURL + 'usernotes/', {
			header: localStorage.getItem('loginToken')
		})
			.then((res: any) => {
				// console.log('notes: \n',res.data.notes);
				// console.log('total notes', res.data.totalNotes);

				//console.log('USERNOTES=====\nresponses here ',res.data.allarticles);
				if (res.data.status === 'success') {
					// setUserPostStats({
					// 	...userPostStats,
					// 	totalNotes: res.data.totalNotes
					// })
					//  console.log('responwee')
					setUserNotes({
						...userNotes,
						notes: res.data.allarticles
					})
				}
			})
	}
	const [titles, setTitles] = useState({
		clicked: false
	});
	const updateNoteTitle = (e: any) => {

	}
	const updateNoteContent = (e: any) => {

		//console.log(e.target.innerHTML);
	}
	const [newNote, setNewNote] = useState('');
	const handleNoteEditor = React.useCallback((e: any) => {
		//console.log(e.target.innerHTML);
		// const sanitizeOptions={
		// 	allowedTags: ["b", "i", "a", "p", "div", "img", "br", "video", "audio", "strong"],
		// 	allowedAttributes: {a: ["href"], 
		// 						img: ["src", "alt"]}
		// };
		// console.log('sanitized ',sanitizeHtml(e.target.innerHTML, sanitizeOptions));
		// setNewNote(sanitizeHtml(e.target.innerHTML, sanitizeOptions));
		setNewNote(e.target.innerHTML);
	}, [])

	const onUpdateNote = (noteId: any) => {
		//console.log('note id ', noteId);
		//console.log('to update title ', toUpdateTitle);
		//console.log('to update content', toUpdateContent);

		if (toUpdateTitle.length > 0 || toUpdateContent.length > 0) {
			axios.post(baseURL + 'updatenote', {
				noteId: noteId,
				header: localStorage.getItem('loginToken'),
				noteTitle: toUpdateTitle,
				noteContent: toUpdateContent
			}).then((res) => {
				if (res.data.status === 'success') {
					//success message here
				} else {
					//error message here
				}
			})
		} else {
			//some error to display here
		}
	}
	const [toUpdateTitle, setToUpdateTitle] = useState('');

	const [toUpdateContent, setToUpdateContent] = useState('');
	const onUpdateNoteTitle = React.useCallback((e: any) => {
		//console.log('title:', e.target.innerHTML);
		setToUpdateTitle(e.target.innerHTML);
	}, [])

	const onUpdateNoteContent = React.useCallback((e: any) => {
		setToUpdateContent(e.target.innerHTML);
		//console.log('content: ', e.target.innerHTML);
	}, [])

	/**
	 * show notes
	 * 
	 * 
	 */
	const showNotes = (e: any) => {
		return <>{e.articles.map((response: any) => {

			return response.map((data: any) => {


				let myObj: any;
				myObj = data;
				return (<><article>
					<div className="user-notes-title">
						<h3 id={"titlenote" + myObj.id} onClick={updateNoteTitle} onBlur={onUpdateNoteTitle} contentEditable={notesState["updateNotesBt" + myObj.id] ? "true" : "false"} >{myObj.noteTitle}</h3>
						<div className="transparent-bt" style={{ display: notesState["updateNotesBt" + myObj.id] ? "none" : "block" }} onClick={() => { changeEachNoteState("updateNotesBt" + myObj.id); setToUpdateContent(myObj.noteContent); setToUpdateTitle(myObj.noteTitle); }} ><UpdateNoteIcon /></div>
					</div>
					{notesState["updateNotesBt" + myObj.id] ? (<div className="allow-update-note" >Vous pouvez modifier cette note</div>) : (<div className="error-update-note" style={{ display: "none" }}>Cliquez sur l' icône crayon pour modifier cette note.</div>)}
					<div id={"contentNote" + myObj.id} className="notes-content"   >

						<><div onClick={updateNoteContent} onBlur={onUpdateNoteContent} contentEditable={notesState["updateNotesBt" + myObj.id] ? "true" : "false"} style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: tools.sanitizeHtml(myObj.noteContent) }} /></>

					</div>
					{notesState["updateNotesBt" + myObj.id] ? (<div className="update-note-footer"><button className="app-bt" onClick={() => changeEachNoteState("updateNotesBt" + myObj.id)}>Annuler</button><button className="app-bt" onClick={() => { onUpdateNote(myObj.id); changeEachNoteState("updateNotesBt" + myObj.id); }}>Modifier</button></div>) : (<></>)}

				</article>

				</>)

			})

		})} </>

	}


	React.useEffect(() => {
		getAllNotes();
	}, [noteSaved])
	return <>
		<div className="app-core-logged-in app-core">
			<div className="app-profile-core">
				<div className="app-profile-page-container">
					<div className="app-profile-wrap">
						<ProfileMenu />
						<div className="app-flex">
							<div className="left-col-form">
								<div className=" ">
									<button className="add-note-bt app-bt" onClick={showAddNoteForm}>{tools.translate('Ajouter une note')} <AddIcon className="add-post-icon" style={{ width: "25", height: "25" }} /></button>

									{/*<button className="app-bt">{tools.translate('Total notes')}: <>{typeof userPostStats.totalNotes !== 'undefined' ? (<><span>{userPostStats.totalNotes}</span></>) : (<>0</>)}</></button>*/}


								</div>
								<div className="addNoteForm modal-container" style={{ display: showAddNote ? "flex" : "none" }}>

									<div className="modal-overlay" onClick={showAddNoteForm}></div>

									<div className="modal-box">
										<form className="user-note  " onSubmit={submitNote}>

											<h2><AuthorIcon />{tools.translate('Ecrire une note')} </h2>
											<label  >{tools.translate('Titre du note')}</label><input id="noteTitle" name="noteTitle" type="text" onChange={handleNoteChange} /><br />
											<label  >{tools.translate('Votre note')}</label>
											<div className="note-editor" contentEditable onBlur={handleNoteEditor} dangerouslySetInnerHTML={{ __html: '' }}>
											</div>
											{/* <textarea name="noteContent" id="noteContent" onChange={handleNoteChange}>
		</textarea> */}
											{/* <p>{newNote}</p> */}
											<div className="add-note-footer">
												{/*<button className="app-bt" onClick={showAddNoteForm}>Annuler</button>*/}
												<button className="app-bt" onBlur={handleNoteEditor} onClick={submitNote}>{tools.translate('enregistrer')}</button>
											</div>
										</form>

									</div> {/*end modal box*/}
								</div>

								<div className="message-sent" style={{ display: noteSaved ? "block" : "none" }}>
									<p>{tools.translate('Votre note a été enregistrée')}</p>
								</div>
							</div> {/*end left col form*/}
							{/*	<div className="upload-form" style={{ display: showUpload ? "block" : "none" }}>
			<UserFiles />
		</div>*/}
							<div className="classrooms-list">
								<p>Total: {userNotes.notes.length}</p>
								<div className="user-notes" style={{ display: userNotes.notes.length > 0 ? "block" : "none" }}>
									<h2>Vos notes</h2>

									{userNotes.notes.map(res => {
										// console.log(res);
										let myObj: any = res;
										return <article key={"userNotes" + myObj.id} >
											<div className="user-notes-title">
												<h3 id={"titlenote" + myObj.id} onClick={updateNoteTitle} onBlur={onUpdateNoteTitle} contentEditable={notesState["updateNotesBt" + myObj.id] ? "true" : "false"} >{myObj.noteTitle}</h3>
												<div className="transparent-bt" style={{ display: notesState["updateNotesBt" + myObj.id] ? "none" : "block" }} onClick={() => { changeEachNoteState("updateNotesBt" + myObj.id); setToUpdateContent(myObj.noteContent); setToUpdateTitle(myObj.noteTitle); }} ><UpdateNoteIcon /></div>
											</div>
											{notesState["updateNotesBt" + myObj.id] ? (<div className="allow-update-note" >Vous pouvez modifier cette note</div>) : (<div className="error-update-note" style={{ display: "none" }}>Cliquez sur l' icône crayon pour modifier cette note.</div>)}
											<div id={"contentNote" + myObj.id} className="notes-content"   >

												<><div onClick={updateNoteContent} onBlur={onUpdateNoteContent} contentEditable={notesState["updateNotesBt" + myObj.id] ? "true" : "false"} style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: tools.sanitizeHtml(myObj.noteContent) }} /></>

											</div>
											{notesState["updateNotesBt" + myObj.id] ? (<div className="update-note-footer"><button className="app-bt" onClick={() => changeEachNoteState("updateNotesBt" + myObj.id)}>Annuler</button><button className="app-bt" onClick={() => { onUpdateNote(myObj.id); changeEachNoteState("updateNotesBt" + myObj.id); }}>Modifier</button></div>) : (<></>)}

										</article>
									})}
								</div>


							</div> {/*end showing notes*/}
							{/*<div className="user-notes">*/}
							{/*<p>Reload: {paginationReload}</p>*/}
							{/*<Pagination url={baseURL + 'usernotes/'} reload={noteSaved} params={{header: localStorage.getItem('loginToken')}} fallBack={showNotes} />*/}
							{/*</div>*/}
							{/* {noteState.noteTitle}
<br/>
{noteState.noteContent} */}
						</div>
					</div>
				</div>
			</div>

		</div>
	</>
}



export default AddNote;
